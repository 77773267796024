@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  color: var(--colorGrey700);
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.addBtn {
  margin-top: 24px;
}
.createListingLink {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border: solid 1px var(--marketplaceColor);
  padding: 10px 20px;
  border-radius: 4px;
  transition: var(--transitionStyle);
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  display: inline-block;
  &:hover {
    transition: var(--transitionStyle);
    background-color: var(--marketplaceColorDark);
    color: var(--colorWhite);
    border: solid 1px var(--marketplaceColorDark);
    text-decoration: none;
  }
}
.resetAllFiltersButton {
  color: var(--marketplaceColor);
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  /* Layout */
  display: inline;
  margin: 5px 0 20px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

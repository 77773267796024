@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 30px 0;
  margin: 0 -10px;
  & .listingCard {
    padding: 0 10px;
    width: calc(100% / 1);
    margin-bottom: 32px;
    @media (--viewportSmall) {
      width: calc(100% / 2);
    }
    @media (--viewportMedium) {
      width: calc(100% / 1);
    }
    @media (--viewportLargeWithPaddings) {
      width: calc(100% / 2);
    }
    @media (min-width: 1600px) {
      width: calc(100% / 3);
    }
  }
}
.searchPageResultPanel {
  & .listingCardsMapVariant {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 30px 0;
    margin: 0 -10px;
    & .listingCard {
      padding: 0 10px;
      width: calc(100% / 1);
      @media (--viewportSmall) {
        width: calc(100% / 2);
      }
      @media (--viewportMedium) {
        width: calc(100% / 3);
      }
      @media (--viewportLarge) {
        width: calc(100% / 4);
      }
      @media (--viewportXLarge) {
        width: calc(100% / 5);
      }
    }
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

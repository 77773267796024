@import '../../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}
.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 300px;
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}
.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0;
  }
}

.inputsWrapper {
  align-items: center;
  display: flex;
}

.minWaterDepth {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  min-height: 40px;
  padding: 0;
  font-size: 14px;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.maxWaterDepth {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  min-height: 40px;
  padding: 0;
  font-size: 14px;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.waterDepthSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .waterDepthSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
